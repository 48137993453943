export const state = () => ({
  featuredBanners: [],
  exclusiveInterviews: [],
  apiNodannaRadio: [],
  chartShow: [],
  scheduleWeekday: [],
  scheduleSaturday: [],
  scheduleSunday: [],
  latestPrograms: [],
  allPrograms: [],
  currentProgram: [],

  program_PmCm: [],
  program_Anupama: [],
  program_Pirith: [],
  program_Asapuwa: [],
  program_Tharaka: [],

  episodes_PmCm: [],
  episodes_Anupama: [],
  episodes_Pirith: [],
  episodes_Asapuwa: [],
  episodes_Tharaka: [],

  playlist: [],
  nowPlaying: {},

  searchResults: [],
})

export const getters = {
  // getterFeaturedBanners: state => {
  //   return state.featuredBanners
  // }
}

export const mutations = {
  UPDATE_FEATURED_BANNERS: (state, payload) => {
    state.featuredBanners = payload
  },
  UPDATE_EXCLUSIVE_INTERVIEWS: (state, payload) => {
    state.exclusiveInterviews = payload
  },
  UPDATE_API_NODANNA_RADIO: (state, payload) => {
    state.apiNodannaRadio = payload
  },
  UPDATE_CHART_SHOW: (state, payload) => {
    state.chartShow = payload
  },
  UPDATE_SCHEDULE_WEEKDAY: (state, payload) => {
    state.scheduleWeekday = payload
  },
  UPDATE_SCHEDULE_SATURDAY: (state, payload) => {
    state.scheduleSaturday = payload
  },
  UPDATE_SCHEDULE_SUNDAY: (state, payload) => {
    state.scheduleSunday = payload
  },
  UPDATE_LATEST_PROGRAMS: (state, payload) => {
    state.latestPrograms = payload
  },
  UPDATE_ALL_PROGRAMS: (state, payload) => {
    state.allPrograms = payload
  },
  UPDATE_CURRENT_PROGRAM: (state, payload) => {
    state.currentProgram = payload
  },

  UPDATE_PROGRAM_PM_CM: (state, payload) => {
    state.program_PmCm = payload
  },
  UPDATE_PROGRAM_ANUPAMA: (state, payload) => {
    state.program_Anupama = payload
  },
  UPDATE_PROGRAM_PIRITH: (state, payload) => {
    state.program_Pirith = payload
  },
  UPDATE_PROGRAM_ASAPUWA: (state, payload) => {
    state.program_Asapuwa = payload
  },
  UPDATE_PROGRAM_THARAKA: (state, payload) => {
    state.program_Tharaka = payload
  },

  UPDATE_EPISODES_PM_CM: (state, payload) => {
    state.episodes_PmCm = payload
  },
  UPDATE_EPISODES_ANUPAMA: (state, payload) => {
    state.episodes_Anupama = payload
  },
  UPDATE_EPISODES_PIRITH: (state, payload) => {
    state.episodes_Pirith = payload
  },
  UPDATE_EPISODES_ASAPUWA: (state, payload) => {
    state.episodes_Asapuwa = payload
  },
  UPDATE_EPISODES_THARAKA: (state, payload) => {
    state.episodes_Tharaka = payload
  },

  UPDATE_PLAYLIST: (state, payload) => {
    state.playlist = payload
  },
  ADD_TO_PLAYLIST: (state, payload) => {
    state.playlist.push(payload)
  },
  ADD_TO_PLAYLIST_END: (state, payload) => {
    state.playlist.unshift(payload)
  },
  UPDATE_NOW_PLAYING: (state, payload) => {
    state.nowPlaying = payload
  },

  UPDATE_SEARCH_RESULTS: (state, payload) => {
    state.searchResults = payload
  },
}

export const actions = {
  async getFeaturedBanners({ state, commit }) {
    if (state.featuredBanners.length) return
    try {
      const featuredBanners = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'featured_banners',
        },
      })
      commit('UPDATE_FEATURED_BANNERS', featuredBanners)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getFeaturedBanners', err)
    }
  },

  async getExclusiveInterviews({ state, commit }) {
    if (state.exclusiveInterviews.length) return
    try {
      let exclusiveInterviews = await this.$axios.$get(
        `${process.env.API_URL_YOUTUBE}/playlistItems`,
        {
          params: {
            part: 'snippet,contentDetails',
            maxResults: 25,
            playlistId: `${process.env.PLAYLIST_ID_CHOOTI_MALLI_PODI_MALLI}`,
            key: `${process.env.KEY_YOUTUBE}`,
          },
        }
      )
      exclusiveInterviews = exclusiveInterviews.items
      commit('UPDATE_EXCLUSIVE_INTERVIEWS', exclusiveInterviews)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getExclusiveInterviews', err)
    }
  },

  async getApiNodannaRadio({ state, commit }) {
    if (state.apiNodannaRadio.length) return
    try {
      let apiNodannaRadio = await this.$axios.$get(
        `${process.env.API_URL_YOUTUBE}/playlistItems`,
        {
          params: {
            part: 'snippet,contentDetails',
            maxResults: 25,
            playlistId: `${process.env.PLAYLIST_ID_BOOM_TOWN}`,
            key: `${process.env.KEY_YOUTUBE}`,
          },
        }
      )
      apiNodannaRadio = apiNodannaRadio.items
      commit('UPDATE_API_NODANNA_RADIO', apiNodannaRadio)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getApiNodannaRadio', err)
    }
  },

  async getChartShow({ state, commit }) {
    if (state.chartShow.length) return
    try {
      let chartShow = await this.$axios.$get(
        `${process.env.API_URL_YOUTUBE}/playlistItems`,
        {
          params: {
            part: 'snippet,contentDetails',
            maxResults: 25,
            playlistId: `${process.env.PLAYLIST_ID_CHART_SHOW}`,
            key: `${process.env.KEY_YOUTUBE}`,
          },
        }
      )
      chartShow = chartShow.items
      commit('UPDATE_CHART_SHOW', chartShow)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getChartShow', err)
    }
  },

  async getLatestPrograms({ state, commit }) {
    if (state.latestPrograms.length) return
    try {
      let latestPrograms = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'latest_radio_programs',
        },
      })
      latestPrograms = latestPrograms.data
      commit('UPDATE_LATEST_PROGRAMS', latestPrograms)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getLatestPrograms', err)
    }
  },

  async getAllPrograms({ state, commit }) {
    if (state.allPrograms.length) return
    try {
      let allPrograms = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'all_radio_programs',
        },
      })
      allPrograms = allPrograms.data
      commit('UPDATE_ALL_PROGRAMS', allPrograms)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getAllPrograms', err)
    }
  },

  async getCurrentProgram({ state, commit }) {
    try {
      let currentProgram = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'current_radio_program',
        },
      })
      currentProgram = currentProgram.data
      commit('UPDATE_CURRENT_PROGRAM', currentProgram)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getCurrentProgram', err)
    }
  },

  async getScheduleWeekday({ state, commit }) {
    if (state.scheduleWeekday.length) return
    try {
      let scheduleWeekday = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'program_shedule_weekday',
        },
      })
      scheduleWeekday = scheduleWeekday.data
      commit('UPDATE_SCHEDULE_WEEKDAY', scheduleWeekday)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getScheduleWeekday', err)
    }
  },

  async getScheduleSaturday({ state, commit }) {
    if (state.scheduleSaturday.length) return
    try {
      let scheduleSaturday = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'program_shedule_saturday',
        },
      })
      scheduleSaturday = scheduleSaturday.data
      commit('UPDATE_SCHEDULE_SATURDAY', scheduleSaturday)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getScheduleSaturday', err)
    }
  },

  async getScheduleSunday({ state, commit }) {
    if (state.scheduleSunday.length) return
    try {
      let scheduleSunday = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'program_shedule_sunday',
        },
      })
      scheduleSunday = scheduleSunday.data
      commit('UPDATE_SCHEDULE_SUNDAY', scheduleSunday)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getScheduleSunday', err)
    }
  },

  async getProgram_PmCm({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_program_pm_cm',
        },
      })
      result = result.data
      commit('UPDATE_PROGRAM_PM_CM', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getProgram_PmCm', err)
    }
  },

  async getProgram_Anupama({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_program_anupama',
        },
      })
      result = result.data
      commit('UPDATE_PROGRAM_ANUPAMA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getProgram_Anupama', err)
    }
  },

  async getProgram_Pirith({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_program_pirith',
        },
      })
      result = result.data
      commit('UPDATE_PROGRAM_PIRITH', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getProgram_Pirith', err)
    }
  },

  async getProgram_Asapuwa({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_program_asapuwa',
        },
      })
      result = result.data
      commit('UPDATE_PROGRAM_ASAPUWA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getProgram_Asapuwa', err)
    }
  },

  async getProgram_Tharaka({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_program_tharaka',
        },
      })
      result = result.data
      commit('UPDATE_PROGRAM_THARAKA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getProgram_Tharaka', err)
    }
  },

  async getEpisodes_PmCm({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_episodes_pm_cm',
        },
      })
      result = result.data
      commit('UPDATE_EPISODES_PM_CM', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getEpisodes_PmCm', err)
    }
  },

  async getEpisodes_Anupama({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_episodes_anupama',
        },
      })
      result = result.data
      commit('UPDATE_EPISODES_ANUPAMA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getEpisodes_Anupama', err)
    }
  },

  async getEpisodes_Pirith({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_episodes_pirith',
        },
      })
      result = result.data
      commit('UPDATE_EPISODES_PIRITH', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getEpisodes_Pirith', err)
    }
  },

  async getEpisodes_Asapuwa({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_episodes_asapuwa',
        },
      })
      result = result.data
      commit('UPDATE_EPISODES_ASAPUWA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getEpisodes_Asapuwa', err)
    }
  },

  async getEpisodes_Tharaka({ state, commit }) {
    try {
      let result = await this.$axios.$get(`${process.env.API_URL}`, {
        params: {
          route: 'get_episodes_tharaka',
        },
      })
      result = result.data
      commit('UPDATE_EPISODES_THARAKA', result)
    } catch (err) {
      // eslint-disable-next-line
      console.error('getEpisodes_Tharaka', err)
    }
  },
}
